/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import Vuex, {
  StoreOptions,
  GetterTree,
  MutationTree,
  ActionTree,
  Mutation,
} from "vuex";
import axios from "axios";
import createMultiTabState from "vuex-multi-tab-state";
import customAxios from "@/axios-service";
import router from "@/router";
import config from "@/config";
import { cloneDeep } from "lodash";
Vue.use(Vuex);
export type Nullable<T> = T | null;
export interface Token {
  access: string;
  refresh: string;
}

export interface Box {
  id: number;
  info: string;
}
//last interface before changes
export interface User_ {
  full_name: string;
  role: string;
}

//new Interface ater changes multiple boxes and profiles
export interface Condos {
  id: number;
  identifier: string;
  name: string;
  primary_address: Address;
  secondary_address: Address;
  status: string;
}

//New profile after changes

export interface CondosResponse {
  condos: Condos[];
  selected_box: number | null;
  selected_condo: number | null;
  selected_role: string | null;
  selected_profile: number | string | null;
}

export interface UserAccount {
  id: number;
  password: string;
  last_login: boolean;
  is_superuser: true;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  identifier: string;
  date_added: string;
  date_modified: string;
  groups: string[];
  user_permissions: string[];
}

export interface CondoAccount {
  id: number;
  identifier: string;
  name: string;
  status: string;
  addresses: Address[];
}

export interface HubState {
  icon: string | null;
  text: string;
  id: number;
  date_modified: string;
  firmware: string;
  state: string;
  status: string;
  hub: number;
}

export interface BoxState {
  icon: string | null;
  text: string;
  id: number;
  date_modified: string;
  connector: string;
  state: string;
  last_change_date: string;
  status: string;
  hub: number;
  box: number;
}

export interface BoxInstalations {
  id: number;
  date_added: string;
  date_modified: string;
  identifier: string;
  connector: string;
  box_meter_initial_energy: number;
  walbox_name: string;
  walbox_model: string;
  walbox_serial_no: string;
  walbox_pin: string;
  installation_test: string;
  other_info: string;
  installation_image: string;
  installation_date: string;
  start_date: string;
  end_date: string;
  status: string;
  installer: number;
  condominium_user_account_profile: number;
  hub: number;
  box: number;
}

export interface Address {
  name: string;
  address: string;
  door: string;
  postal_code: string;
  city: string;
  country: string;
  tax_identification_number: string;
  floor: string;
  type: string | null;
}

export interface User {
  id: number;
  identifier: string;
  name: string;
  user_account: UserAccount;
  condominium_account: CondoAccount;
  profile_type: string;
  contact_address: string;
  contact_email: string;
  contact_phone: string;
  start_date: string;
  end_date: null;
  box_installations: BoxInstalations[];
  addresses: Address[];
}

//End of new profile after changes

export interface ProfileInfo {
  selected_condo: number;
  selected_role: string | null;
  selected_box: number | null;
  condos: Condos[];
}

export interface status {
  id: number;
  type: string;
  status: boolean;
}

export interface Consumptions {
  user: string;
  sessions: Session[];
  totals: Cost;
  updated_at: DateTime;
}

export interface PeriodsDate {
  billing_period: BillingPeriod;
  confirmed: boolean;
  updated_at: DateTime;
  totals: Cost;
  consumptions: Consumptions[];
}

export interface DashboardUserData {
  statuses: status[];
  billing_periods_options: BillingDate[];
  periods_data: PeriodsDate[];
  latest: boolean;
}

export interface DashboardManagerData {
  statuses: status[];
  billing_periods_options: BillingDate[];
  periods_data: PeriodsDate[];
  latest: boolean;
}

//end of new Interface ater changes multiple boxes and profiles

export interface BillingDate {
  start_date: string;
  end_date: string;
}
export interface BillingPeriod {
  start_date: string;
  end_date: string;
}
export interface DateTime {
  date: string;
  time: string;
}
export interface Cost {
  total_energy: string;
  total_cost: string;
}
export interface Session {
  box_id: number;
  start_time: DateTime;
  duration: string;
  costs: Cost;
}
export interface UserConsumption {
  user: string;
  totals: Cost;
}

export interface Consumption {
  user: string;
  sessions: Session[];
  totals: Cost;
}

export interface ConsumptionData {
  confirmed: boolean;
  consumptions: Consumption[];
  totals: Cost;
  update_billing_info: boolean;
  updated_at: DateTime;
}

export interface Faq {
  title: string;
  text: string;
}
export interface FaqList {
  title: string;
  faq: Faq;
}
export interface Snackbar {
  show: boolean | false;
  message: string | "";
  timeout: number | 7000;
  color: string | "#263238";
  percentage: number | null;
  showButton: boolean | null;
}
export interface ChargingState {
  icon: string | "";
  color: string | "";
  text: string | "";
  state: string | "";
}
type tariffInfoIndex = {
  [key: string]: TariffInfo;
};
export interface NameBooleanState {
  name: string;
  status: boolean;
}

export interface Tariffs {
  energy: number;
  grid: number;
  total: number;
  name: string;
  text: string;
}

export interface DateF {
  display_format: string;
  send_format: string;
}

export interface FaqData {
  category: string | null;
  sub_category: string | null;
  document_upload: Array<File>;
  message: string | null;
  state: string | null;
}

export interface ContractData {
  bp_start_date: string | any;
  bp_end_date: string | any;
  tariff_groups: TariffInfo[];
  editable_bill: boolean;

  cycle: Nullable<number>;
  plan: string | null;
  contracted_power: Nullable<number>;
  schedule_type: string;
  regulated_market: boolean | null;
}

export interface TariffInfo {
  power_price: Nullable<number>;
  t1_energy_price: Nullable<number>;
  t2_energy_price: Nullable<number>;
  t3_energy_price: Nullable<number>;
  t4_energy_price: Nullable<number>;
  t1_grid_price: Nullable<number>;
  t2_grid_price: Nullable<number>;
  t3_grid_price: Nullable<number>;
  t4_grid_price: Nullable<number>;
  mec_ajuste: Nullable<number>;
  banda_reg: Nullable<number>;
  tariff_plan: Tariffs[];
  start_date: string;
  end_date: string;
}

export interface RootState {
  recovery_token: string;
  period_edit: number;
  billing_address_user_length: Address;
  billing_address_condo_length: Address;
  number_tariffs: number;
  edit_number_tariffs: number;
  billing_periods: BillingPeriod[];
  box_state: BoxState[];
  hub_state: HubState[];
  change_password: boolean;
  token: Token;
  snackbar: Snackbar;
  button_state: NameBooleanState[];
  modal_state: NameBooleanState[];
  profiles: User[];
  user: User;
  condos_response: CondosResponse;
  consumption_data: ConsumptionData;
  consumption_data_prev: ConsumptionData;
  sliced_session: Session[];
  start: number;
  counter: number;
  google_cookie: NameBooleanState;
  privacy_cookie: NameBooleanState;
  next_enabled: boolean;
  buttonDisabled: boolean;
  consumptionDataUpdate: boolean;
  prev_enabled: boolean;
  period: number;
  charging_state: ChargingState;
  active_tab: number;
  active_tab_profile: number;
  active_tab_faq: number;
  tariff_info: TariffInfo[];
  contract_data: ContractData[];
  faqs: FaqList[];
  faq_data: FaqData;
  s_date: DateF;
  e_date: DateF;
  street: string;
  historic_data: ContractData[];
  edit_data: ContractData[];
  selected_consumption: Consumption;
  selected_consumption_before: Consumption;
  is_change_password: boolean;
}

const getters: GetterTree<RootState, any> = {
  periodState: (state) => state.period,
  currentToken: (state) => state.token,
  numberTariffs: (state) => state.contract_data[0].tariff_groups.length,
  numberEditTariffs: (state) => state.edit_data[0].tariff_groups.length,

  historicData: (state) => {
    state.historic_data;
  },

  isAuthenticated: (state) => state.token != null,
  buttonState: (state) => (param: string) =>
    state.button_state &&
    state.button_state.length > 0 &&
    state.button_state.find((el) => el.name == param) !== undefined
      ? state.button_state.find((el) => el.name == param)?.status
      : false,
  modalState:
    (state) =>
    (param = "", defaultStatus = false) =>
      state.modal_state &&
      state.modal_state.length > 0 &&
      state.modal_state.find((el) => el.name == param) !== undefined
        ? state.modal_state.find((el) => el.name == param)?.status
        : defaultStatus,
  googleCookie: (state) =>
    state.google_cookie &&
    state.google_cookie.name &&
    state.google_cookie.name == "15-02-2022"
      ? state.google_cookie.status
      : true,

  privacyCookie: (state) =>
    state.privacy_cookie &&
    state.privacy_cookie.name &&
    state.privacy_cookie.name == "15-02-2022"
      ? state.privacy_cookie.status
      : true,

  tariffInfo: (state) => (param: keyof TariffInfo[]) =>
    state.tariff_info && state.tariff_info[param] !== undefined
      ? state.tariff_info[param]
      : null,
  faqsText: (state) => (param: keyof FaqList | any | null) =>
    param ? (state.faqs ? state.faqs[param] : null) : state.faqs,
  faqData: (state) => (param: keyof FaqData) =>
    state.faq_data ? state.faq_data[param] : null,
  condoData: (state) => (param: keyof CondosResponse) =>
    state.condos_response
      ? state.condos_response[param]
      : state.condos_response,
  contractData: (state) => (param: keyof ContractData | keyof TariffInfo) => {
    if (
      state.contract_data &&
      state.contract_data[0].tariff_groups !== undefined
    ) {
      if (
        param == "bp_start_date" ||
        param == "bp_end_date" ||
        param == "cycle" ||
        param == "plan" ||
        param == "contracted_power" ||
        param == "schedule_type" ||
        param == "regulated_market" ||
        param == "editable_bill"
      ) {
        return state.contract_data[0][param];
      } else if (
        param == "start_date" ||
        param == "end_date" ||
        param == "power_price" ||
        param == "t1_energy_price" ||
        param == "t2_energy_price" ||
        param == "t3_energy_price" ||
        param == "t4_energy_price" ||
        param == "t1_grid_price" ||
        param == "t2_grid_price" ||
        param == "t3_grid_price" ||
        param == "t4_grid_price" ||
        param == "mec_ajuste" ||
        param == "banda_reg" ||
        param == "tariff_plan"
      ) {
        return state.contract_data[0].tariff_groups[state.period][param];
      }
    }
  },
  editData: (state) => (param: keyof ContractData | keyof TariffInfo) => {
    if (state.edit_data && state.edit_data[0].tariff_groups !== undefined) {
      if (
        param == "bp_start_date" ||
        param == "bp_end_date" ||
        param == "cycle" ||
        param == "plan" ||
        param == "contracted_power" ||
        param == "schedule_type" ||
        param == "regulated_market" ||
        param == "editable_bill"
      ) {
        return state.edit_data[0][param];
      } else if (
        param == "start_date" ||
        param == "end_date" ||
        param == "power_price" ||
        param == "t1_energy_price" ||
        param == "t2_energy_price" ||
        param == "t3_energy_price" ||
        param == "t4_energy_price" ||
        param == "t1_grid_price" ||
        param == "t2_grid_price" ||
        param == "t3_grid_price" ||
        param == "t4_grid_price" ||
        param == "mec_ajuste" ||
        param == "banda_reg" ||
        param == "tariff_plan"
      ) {
        return state.edit_data[0].tariff_groups[state.period_edit][param];
      }
    }
  },

  condoResponse: (state) => state.condos_response,
  userRole: (state) => state.condos_response.selected_role,
  profileInfo: (state: any) => (param: any) => {
    const profileIndex = state.profiles.findIndex(
      (val: any) => val.id == state.condos_response.selected_profile
    );
    // Validate if profile is found
    if (profileIndex == -1) {
      return ""; // or handle this case as needed
    }
    const addresses =
      state.profiles[profileIndex].condominium_account.addresses;
    const addresses_user = state.profiles[profileIndex].addresses;
    const defaultAddressUserIndex = addresses_user.findIndex(
      (address: any) => address.type == "Default Address"
    );
    const defaultAddressIndex = addresses.findIndex(
      (address: any) => address.type == "Default Address"
    );
    if (
      param
        ? param.account == "condominium_account"
        : null && param.address_type
    ) {
      if (param.address_type == "Billing Address") {
        return (
          state.profiles?.[profileIndex]?.condominium_account?.addresses?.[0]?.[
            param.key
          ] ?? ""
        );
      } else if (param.address_type == "Default Address") {
        return state.profiles[profileIndex].condominium_account.addresses[
          defaultAddressIndex
        ][param.key];
      } else {
        return state.profiles[profileIndex].condominium_account[param.key];
      }
    } else {
      if (param.address_type == "Billing Address" && param.address_type) {
        return (
          state.profiles?.[profileIndex]?.addresses?.[0]?.[param.key] ?? ""
        );
      } else if (param.address_type == "Default Address") {
        return state.profiles[profileIndex].addresses[defaultAddressUserIndex][
          param.key
        ];
      } else {
        return state.profiles[profileIndex][param.key];
      }
    }
  },
};

const mutations: MutationTree<RootState> = {
  setRecoverToken: (state, token: string) => {
    state.recovery_token = token;
  },

  resetBillingAddressUser: (state) => {
    const keys = Object.keys(state.billing_address_user_length);
    keys.forEach((key: any) => {
      if (key != "type") (state.billing_address_user_length as any)[key] = "";
    });
  },

  resetFaqData: (state: any) => {
    Object.keys(state.faq_data).forEach((i) => {
      if (i != "document_upload") state.faq_data[i] = null;
      else state.faq_data[i] = [];
    });
  },

  updateChangePassword: (state, val: boolean) => {
    state.is_change_password = val;
  },

  updateBillingAddressUserLength: (state, val: number) => {
    if (state.profiles.length != 0) {
      const profileIndex = state.profiles.findIndex(
        (val: any) => val.id == state.condos_response.selected_profile
      );
      const selectedAddress = state.profiles[profileIndex].addresses.findIndex(
        (address: any) => address.type == "Billing Address"
      );
      if (val != undefined && val != null) {
        Object.assign(state.billing_address_user_length, val);
      }
    }
  },
  updateBillingAddressCondoLength: (state, val: number) => {
    const profileIndex = state.profiles.findIndex(
      (val: any) => val.id == state.condos_response.selected_profile
    );
    const selectedAddress = state.profiles[
      profileIndex
    ].condominium_account.addresses.findIndex(
      (address: any) => address.type == "Billing Address"
    );
    Object.assign(state.billing_address_condo_length, val);
  },

  updateToken: (state, token: Token) => {
    state.token = token;
    sessionStorage.setItem("token", JSON.stringify(state.token));
  },

  setStreet: (state, val: any) => {
    state.street = val;
  },

  updateBoxState: (state, val: any) => {
    state.box_state = val;
  },

  updateBillingPeriods: (state, val: any) => {
    state.billing_periods = val;
  },

  updateHubState: (state, val: any) => {
    state.hub_state = val;
  },

  deleteFoto: (state, val: any) => {
    state.faq_data.document_upload?.splice(val.id, 1);
  },
  updatePeriod: (state, val: any) => {
    state.period = val;
  },

  updatePeriodEdit: (state, val: any) => {
    state.period_edit = val;
  },

  updateTokenAccess: (state, access: string) => {
    state.token.access = access;
    sessionStorage.setItem("token", JSON.stringify(state.token));
  },
  toggleButtonState: (state, value: any) => {
    if (!state.button_state || !value) {
      state.button_state = [];
    }
    if (value) {
      const index = state.button_state.length
        ? state.button_state.findIndex((el) => el.name == value.name)
        : -1;
      if (index !== -1) {
        state.button_state[index].status = value.status;
      } else {
        state.button_state.push(value);
      }
    }
    sessionStorage.setItem("button-state", JSON.stringify(state.button_state));
  },
  toggleModalState: (state, value: any) => {
    if (!state.modal_state || !value) {
      state.modal_state = [];
    }
    if (value) {
      const index =
        state.modal_state.length > 0
          ? state.modal_state.findIndex((el) => el.name == value.name)
          : -1;
      if (index !== -1) {
        state.modal_state[index].status = value.status;
      } else {
        state.modal_state.push(value);
      }
    }
    sessionStorage.setItem("modal-state", JSON.stringify(state.modal_state));
  },
  toggleGoogleCookie: (state, value: boolean) => {
    state.google_cookie = { name: "15-02-2022", status: value };
    sessionStorage.setItem(
      "google-cookie",
      JSON.stringify(state.google_cookie)
    );
  },

  togglePrivacyCookie: (state, value: boolean) => {
    state.privacy_cookie = { name: "15-02-2022", status: value };
    sessionStorage.setItem(
      "privacy-cookie",
      JSON.stringify(state.privacy_cookie)
    );
  },
  updateSnackbar: (state, snackbar: Snackbar) => {
    //aqui
    state.snackbar = snackbar;
  },
  updateUserAcc: (state, user: User[]) => {
    //const profileIndex = state.profiles.findIndex((val:any) => val.id == state.condos_response.selected_profile);

    state.profiles = user;
  },
  updateUser: (state, user: User) => {
    state.user = user;
    sessionStorage.setItem("user", JSON.stringify(state.user));
  },
  sliceSession: (state, vals: any) => {
    const fval = Object.assign([], vals.sessions);
    state.sliced_session = JSON.parse(
      JSON.stringify(fval.splice(vals.start, vals.length))
    );
  },

  updateTariffInfo: (state, val: any) => {
    Object.assign(state.tariff_info, val);
  },

  updateProfileInfo: (state: any, val: any) => {
    const profile_index = state.profiles.findIndex(
      (val: any) => val.id == state.condos_response.selected_profile
    );
    const addrex_index_user = state.profiles[profile_index].addresses.findIndex(
      (address: any) => address.type == val.type || address.key == val.type
    );
    const address_index_condo = state.profiles[
      profile_index
    ].condominium_account.addresses.findIndex(
      (address: any) => address.type == val.type || address.key == val.type
    );
    const address_condo_length =
      state.profiles[profile_index].condominium_account.addresses.length;
    const address_user_length = state.profiles[profile_index].addresses.length;

    if (state.condos_response.selected_role != "user") {
      if (val.type == "Billing Address") {
        if (address_index_condo == -1) {
          state.profiles[profile_index].condominium_account.addresses.push(val);
        } else {
          Object.assign(
            state.profiles[profile_index].condominium_account.addresses[
              address_index_condo
            ],
            val
          );
        }
      } else {
        Object.assign(state.profiles[profile_index], val);
      }
    } else {
      if (state.condos_response.selected_role == "user") {
        if (val.type != "Billing Address") {
          Object.assign(state.profiles[profile_index], val);
        } else {
          if (addrex_index_user == -1) {
            state.profiles[profile_index].addresses.push(val);
          } else {
            Object.assign(
              state.profiles[profile_index].addresses[addrex_index_user],
              val
            );
          }
        }
      }

      // if((val.key == "primary_address" || val.key == "secondary_address") && state.condos_response.selected_role != 'user'){
      //   delete (Object.assign(state.profiles[state.profiles.findIndex((val:any) => val.id == state.condos_response.selected_profile)].condominium_account[val.key], val)).key
      // }else if((val.key == "primary_address" || val.key == "secondary_address") && state.condos_response.selected_role == 'user'){
      //   delete (Object.assign(state.profiles[state.profiles.findIndex((val:any) => val.id == state.condos_response.selected_profile)][val.key], val)).key
      // }
    }
  },

  updateCondosInfo: (state: any, val: any) => {
    //state.condos_response.selected_role = 'user'
    const obj = { ...state.condos_response, ...val };
    state.condos_response = obj;
  },

  updateFaqInfo: (state: any, val: any) => {
    if (val.key == "fotos") {
      state.faq_data.document_upload.push(val.value);
    } else state.faq_data[val.key] = val.value;
  },

  updateEditInfo: (state: any, val: any) => {
    if (
      Object.prototype.hasOwnProperty.call(val, "bp_start_date") == true ||
      Object.prototype.hasOwnProperty.call(val, "bp_end_date") == true ||
      Object.prototype.hasOwnProperty.call(val, "plan") == true ||
      Object.prototype.hasOwnProperty.call(val, "contracted_power") == true ||
      Object.prototype.hasOwnProperty.call(val, "schedule_type") == true ||
      Object.prototype.hasOwnProperty.call(val, "cycle") == true ||
      Object.prototype.hasOwnProperty.call(val, "regulated_market") == true
    ) {
      delete Object.assign(state.edit_data[0], val).period;
    } else {
      Object.assign(state.edit_data[0].tariff_groups[val.period], val);
    }
  },

  updateContractInfo: (state: any, val: any) => {
    if (
      Object.prototype.hasOwnProperty.call(val, "bp_start_date") == true ||
      Object.prototype.hasOwnProperty.call(val, "bp_end_date") == true ||
      Object.prototype.hasOwnProperty.call(val, "plan") == true ||
      Object.prototype.hasOwnProperty.call(val, "contracted_power") == true ||
      Object.prototype.hasOwnProperty.call(val, "schedule_type") == true ||
      Object.prototype.hasOwnProperty.call(val, "cycle") == true ||
      Object.prototype.hasOwnProperty.call(val, "regulated_market") == true
    ) {
      delete Object.assign(state.contract_data[0], val).period;
    } else {
      Object.assign(state.contract_data[0].tariff_groups[val.period], val);
    }
  },

  getNumberTariffs: (state) => {
    state.number_tariffs = state.contract_data[0].tariff_groups.length;
  },

  getEditTariffs: (state) => {
    state.number_tariffs = state.edit_data[0].tariff_groups.length;
  },

  setNumberTariffs: (state, val: any) => {
    state.number_tariffs = state.number_tariffs + val;
  },
  setNumberEditTariffs: (state, val: any) => {
    state.edit_number_tariffs = state.edit_number_tariffs + val;
  },
  setCondoResponse: (state, val: any) => {
    //
  },

  removeGridPowerContract: (state, val: any) => {
    state.contract_data[0].tariff_groups[val].t1_grid_price = 0;
    state.contract_data[0].tariff_groups[val].t2_grid_price = 0;
    state.contract_data[0].tariff_groups[val].t3_grid_price = 0;
    state.contract_data[0].tariff_groups[val].t4_grid_price = 0;
  },

  removePeriod: (state, val: any) => {
    state.contract_data[0].tariff_groups.splice(val, 1);
  },

  removePeriodEdit: (state, val: any) => {
    state.edit_data[0].tariff_groups.splice(val, 1);
  },

  addNewPeriod: (state) => {
    state.contract_data[0].tariff_groups[
      state.contract_data[0].tariff_groups.length - 1
    ].end_date;
    if (
      state.contract_data[0].tariff_groups[
        state.contract_data[0].tariff_groups.length - 1
      ].end_date
    ) {
      const end_date_last = new Date(
        state.contract_data[0].tariff_groups[
          state.contract_data[0].tariff_groups.length - 1
        ].end_date
      );

      end_date_last.setDate(end_date_last.getDate() + 1);
      const dateString: string = end_date_last.toISOString().split("T")[0];
      state.contract_data[0].tariff_groups.push({
        ...state.contract_data[0].tariff_groups[
          state.contract_data[0].tariff_groups.length - 1
        ],
        start_date: dateString,
        end_date: "",
      });
    } else {
      state.contract_data[0].tariff_groups.push({
        ...state.contract_data[0].tariff_groups[
          state.contract_data[0].tariff_groups.length - 1
        ],
        start_date: "",
        end_date: "",
      });
    }
  },

  addNewPeriodEdit: (state) => {
    state.edit_data[0].tariff_groups[
      state.edit_data[0].tariff_groups.length - 1
    ].end_date;
    if (
      state.edit_data[0].tariff_groups[
        state.edit_data[0].tariff_groups.length - 1
      ].end_date
    ) {
      const end_date_last = new Date(
        state.edit_data[0].tariff_groups[
          state.edit_data[0].tariff_groups.length - 1
        ].end_date
      );

      end_date_last.setDate(end_date_last.getDate() + 1);
      const dateString: string = end_date_last.toISOString().split("T")[0];
      state.edit_data[0].tariff_groups.push({
        ...state.edit_data[0].tariff_groups[
          state.edit_data[0].tariff_groups.length - 1
        ],
        start_date: dateString,
        end_date: "",
      });
    } else {
      state.edit_data[0].tariff_groups.push({
        ...state.edit_data[0].tariff_groups[
          state.edit_data[0].tariff_groups.length - 1
        ],
        start_date: "",
        end_date: "",
      });
    }
  },

  updateCondoResponse: (state) => {
    const condos = state.profiles.reduce((acc: any, user: any) => {
      const existingCondo = acc.find(
        (condo: any) => condo.id == user.condominium_account.id
      );

      // If condo doesn't exist in the accumulator, add it
      if (!existingCondo) {
        acc.push({
          id: user.condominium_account.id,
          identifier: user.condominium_account.identifier,
          name: user.condominium_account.name,
          addresses: user.condominium_account.addresses,
          status: user.condominium_account.status,
        });
      }

      return acc;
    }, []);
    let selectedRole = "manager"; // Set manager as default
    let selectedProfile: any = null;
    if (
      state.profiles.some(
        (user: any) =>
          user.profile_type == "Condominium Manager" ||
          user.profile_type == "Condominium Administrator" ||
          user.profile_type == "Condominium Owner"
      )
    ) {
      selectedProfile =
        state.profiles[
          state.profiles.findIndex(
            (user: any) =>
              user.profile_type == "Condominium Manager" ||
              user.profile_type == "Condominium Administrator" ||
              user.profile_type == "Condominium Owner"
          )
        ].id;
      selectedRole = "manager";
    } else if (
      (state.profiles[0] &&
        state.profiles[0].profile_type == "Apartment Owner") ||
      state.profiles[0].profile_type == "Apartment Tenant"
    ) {
      selectedRole = "user";
      selectedProfile =
        state.profiles[
          state.profiles.findIndex(
            (user: any) =>
              user.profile_type == "Apartment Owner" ||
              user.profile_type == "Apartment Tenant"
          )
        ].id;
    }

    let selectedBox = null;
    if (
      selectedRole == "user" &&
      state.profiles[0].box_installations.length > 0
    ) {
      selectedBox = state.profiles[0].box_installations[0].id;
      selectedProfile = state.profiles[0].id;
    }

    const selectedProfile_ =
      state.profiles[
        state.profiles.findIndex((user: any) => user.id == selectedProfile)
      ];
    const condo_Id = selectedProfile_.condominium_account.id;
    const condoId = condos.findIndex((val: any) => val.id == condo_Id);
    localStorage.setItem("selected_condo", condos[condoId].id);
    localStorage.setItem("selected_role", selectedRole);
    localStorage.setItem("selected_profile", selectedProfile as any);
    localStorage.setItem("condos", JSON.stringify(condos));
    localStorage.setItem("selected_box", selectedBox as any);
    state.condos_response.condos = condos;
    state.condos_response.selected_condo = condos[condoId].id;
    state.condos_response.selected_box = selectedBox;
    state.condos_response.selected_role = selectedRole;
    state.condos_response.selected_profile = selectedProfile;
  },

  updateState: (state, val: any) => {
    Object.assign(state, val);
  },
  updateHistoric: (state, val: any) => {
    state.historic_data = val.resp1;
  },
  updateButtonState: (state, val: any) => {
    state.buttonDisabled = val;
  },
  refreshConsumptionData: (state, val: any) => {
    state.consumptionDataUpdate = val;
  },

  setFile(state: any, files: any) {
    state.faq_data.document_upload.push(...files);
  },

  updateBillingAddress(state, val: any) {
    if (val[0] != undefined) {
      if (val[0].condominium_account.addresses) {
        const userBillingAddress = val[0].condominium_account.addresses.filter(
          (address: any) => address.type == "Billing Address"
        )[0];
        const condoBillingAddress = val[0].condominium_account.addresses.filter(
          (address: any) => address.type == "Billing Address"
        )[0];
        if (userBillingAddress != undefined)
          state.billing_address_user_length = userBillingAddress;
        if (condoBillingAddress != undefined)
          state.billing_address_condo_length = condoBillingAddress;
      }
    }
  },
};
const actions: ActionTree<RootState, any> = {
  uploadFile({ commit }, files) {
    // Any async operations could go here, such as uploading the file to a server

    // Commit the mutation to update the state
    commit("setFile", files);
  },

  async error({ commit, dispatch, getters }, e) {
    e.response
      ? e.response.status == 401 && router.currentRoute.name == "Login"
        ? dispatch("toggleSnackbar", {
            show: true,
            message: e.response.data.detail,
            // message: e.response.data.detail,
          })
        : e.response.status == 403 || e.response.status == 401
          ? this.dispatch("logout")
          : e.response.data && e.response.status == 400
            ? dispatch("toggleSnackbar", {
                show: true,
                message: e.response.data.error,
              })
            : null
      : config.errorMessages.unknown_error;
    return false;
  },

  async requestSupport({ commit, dispatch }, data) {
    return customAxios
      .post(`/api-support/request/`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .catch((e) => {
        return dispatch("toggleSnackbar", {
          show: true,
          message: e.response.data.error,
        });
      });
  },

  async requestUser({ commit, dispatch }) {
    return customAxios
      .get("/api-condo/condo_user_account_profiles/")
      .then(async (resp) => {
        commit("updateUserAcc", resp.data);
        commit("updateBillingAddress", resp.data);
        //Populating with dummy data for now
        //commit("updateUserAcc", resp)
      })
      .catch((e) => {
        //commit("updateUserAcc", config.userData);
        //Populating with dummy data for now
        return dispatch("toggleSnackbar", {
          show: true,
          message: e.response.data.error,
        });
      });
  },

  async activateSnackBar({ commit, dispatch }) {
    commit("updateButtonState", true);
    customAxios
      .get(
        `/api-sessions/sessions_update_status/?condominium_account=${localStorage.getItem("selected_condo")}`
      )
      .then(async (response) => {
        if (response.data.status == "Processed") {
          localStorage.setItem("showConsumptionValuesSnackBar", "false");
          this.dispatch("toggleSnackbar", {
            show: true,
            message: "Valores dos consumos actualizados",
            color: "green",
            timeout: 8000,
          });
          commit("updateButtonState", false);
        } else {
          localStorage.setItem("showConsumptionValuesSnackBar", "true");
          const percentage = (response.data.progress * 100).toPrecision(2);
          this.dispatch("toggleSnackbar", {
            //aqui
            show: true,
            message: "A atualizar os valores dos consumo",
            color: "#F2B658",
            timeout: "0",
            percentage: percentage,
          });
          setTimeout(() => dispatch("activateSnackBar"), 5000);
        }
      })
      .catch((e) => {
        return dispatch("toggleSnackbar", {
          show: true,
          message: e.response,
        });
      });
  },

  async login({ commit, dispatch }, data) {
    return customAxios
      .post(`/api/token/`, data)
      .then(async (response) => {
        commit("updateToken", response.data);
        commit("updateState", { active_tab: 0 });
        commit("updateState", { active_tab_faq: 0 });
        await this.dispatch("requestUser").then(async (resp) => {
          commit("updateCondoResponse");
        });
        return router.replace({ path: "/dashboard" });

        //return this.dispatch("requestUser");
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },
  async getUser({ commit, dispatch }) {
    return customAxios
      .get(`/api/user_record/`)
      .then(async (response: any) => {
        commit("updateUser", response.data);

        return router.replace({ path: "/dashboard" });
      })
      .catch(async (e: any) => {
        commit("updateToken", null);

        return this.dispatch("error", e);
      });
  },

  async getFaqData({ commit, dispatch }) {
    return customAxios
      .get(`/api-support/faq/`)
      .then(async (response: any) => {
        const data = await response.data;
        commit("updateState", { faqs: data });
      })
      .catch(async (e: any) => {
        return dispatch("error", e);
      });
  },

  async getConsumptionData2({ commit, dispatch }, data) {
    return customAxios
      .get(`${data.uri}/${data.query}`)
      .then(async (response) => {
        return commit("updateState", { consumption_data_prev: response.data });
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },
  async getConsumptionData({ commit, dispatch }, data) {
    return customAxios
      .get(`${data.uri}/${data.query}`)
      .then(async (response: any) => {
        if (
          response.data.update_billing_info &&
          this.state.condos_response.selected_role == "manager"
        ) {
          localStorage.setItem("toggleSnackbarPastThirtyDays", "true");
          this.dispatch("toggleSnackbarPastThirtyDays", {
            show: true,
            message: "Actualize suas tarifas e evite custos estimados",
            color: "#F2B658",
            timeout: "0",
            showButton: true,
          });
        } else {
          localStorage.setItem("toggleSnackbarPastThirtyDays", "false");
        }
        return commit("updateState", { consumption_data: response.data });
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },

  async getHubsList({ commit, dispatch }, data) {
    return customAxios
      .get(
        `/api-mon/hub_state/?profile_id=${data.profile_id ? data.profile_id : data}`
      )
      .then(async (response) => {
        const data = response.data;
        commit("updateHubState", data);
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },

  async getBoxList({ commit, dispatch }, data) {
    return customAxios
      .get(`/api-mon/box_state/?profile_id=${data.profile_id}`)
      .then(async (response) => {
        const data = response.data;
        commit("updateBoxState", data);
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },

  async getBillingPeriods({ commit, dispatch }) {
    return customAxios
      .get(
        `/api-bills/billing_periods/?profile_id=${this.state.condos_response.selected_profile}`
      )
      .then(async (response) => {
        const data = response.data;
        commit("updateBillingPeriods", data);
      })
      .catch((e) => {
        dispatch("toggleSnackbar", {
          show: true,
          color: "red",
          message: e.response.data.error,
        });
      });
  },

  async getContractData({ commit, dispatch }, data) {
    return customAxios
      .get(
        `/api-bills/${data.uri}/?profile_id=${this.state.condos_response.selected_profile}`
      )
      .then(async (response) => {
        const resp = response.data;
        if (resp.length != 0) {
          resp[0].tariff_groups.reverse();
          const resp1 = cloneDeep(resp);
          commit("updateState", { edit_data: [resp[0]] });
          dispatch("updateBillEditValue", resp);
          commit("updateHistoric", { resp1 });
        }
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },

  async sendPut({ commit, dispatch }, data) {
    await dispatch("toggleSnackbar", null);
    commit("refreshConsumptionData", true);
    const btn_name = data.btn_name;
    const uri = data.uri;
    const show_success = data.show_success;
    delete data["uri"];
    delete data["btn_name"];
    delete data["show_success"];

    commit("toggleButtonState", {
      name: btn_name,
      status: true,
    });
    return customAxios
      .put(
        `/api-bills/${uri}/?profile_id=${this.state.condos_response.selected_profile}`,
        data[0]
      )
      .then(async (response) => {
        return response.data;
      })
      .catch((e) => {
        commit("updateButtonState", false);
        dispatch("toggleSnackbar", {
          show: true,
          color: "red",
          message: e.response.data.error,
        });
      });
  },
  async sendCondoProfileData({ dispatch }, data) {
    return customAxios
      .post(
        `/api-condo/condo_address/?profile_id=${this.state.condos_response.selected_profile}`,
        data
      )
      .then(async (response: any) => {
        if (response.data && response.data.message) {
          return dispatch("toggleSnackbar", {
            show: true,
            message: response.data.message,
            color: "green",
          });
        }

        return response.data;
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },

  async sendPartialUpdate({ dispatch }, data) {
    return customAxios
      .patch(
        `/api-condo/condo_user_account_profiles/?profile_id=${this.state.condos_response.selected_profile}`,
        data
      )
      .catch((e) => {
        return dispatch("toggleSnackbar", {
          show: true,
          message: e.response.data.error,
        });
      });
  },

  async sendUserProfile({ dispatch }, data) {
    return customAxios
      .post(
        `/api-condo/user_profile_address/?profile_id=${this.state.condos_response.selected_profile}`,
        data
      )
      .then(async (response: any) => {
        if (response.data && response.data.message) {
          return dispatch("toggleSnackbar", {
            show: true,
            message: response.data.message,
            color: "green",
          });
        }

        return response.data;
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },

  async sendReport({ commit, dispatch }, data) {
    await dispatch("toggleSnackbar", null);
    const btn_name = data.btn_name;

    const uri = data.uri;
    const show_success = data.show_success;
    delete data["uri"];
    delete data["btn_name"];
    delete data["show_success"];
    commit("toggleButtonState", {
      name: btn_name,
      status: true,
    });

    return customAxios
      .post(
        `/api-condo/email_report/?profile_id=${this.state.condos_response.selected_profile}`,
        data
      )
      .then(async (response: any) => {
        if (show_success && response.data && response.data.message) {
          return dispatch("toggleSnackbar", {
            show: true,
            message: response.data.message,
            color: "green",
          });
        }

        return response.data;
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },

  async sendPost({ commit, dispatch }, data) {
    await dispatch("toggleSnackbar", null);
    const btn_name = data.btn_name;

    const uri = data.uri;
    const show_success = data.show_success;
    delete data["uri"];
    delete data["btn_name"];
    delete data["show_success"];

    commit("toggleButtonState", {
      name: btn_name,
      status: true,
    });

    return customAxios
      .post(
        `/api-bills/${uri}/?profile_id=${this.state.condos_response.selected_profile}`,
        data
      )
      .then(async (response: any) => {
        if (show_success && response.data && response.data.message) {
          return dispatch("toggleSnackbar", {
            show: true,
            message: response.data.message,
            color: "green",
          });
        }
        return response.data;
      })
      .catch(async (e) => {
        return this.dispatch("error", e);
      });
  },
  async toggleSnackbar({ commit }, data) {
    //aqui
    if (data) {
      return commit("updateSnackbar", {
        show: data?.show || false,
        message: data?.message || "",
        timeout: data?.timeout || 8000,
        color: data?.color || "#BF360C",
        percentage: data?.percentage || null,
        showButton: data?.showButton || null,
      });
    }
  },
  async toggleSnackbarPastThirtyDays({ commit }, data) {
    const showConsumptionValuesSnackBar = localStorage.getItem(
      "showConsumptionValuesSnackBar"
    );
    if (data && showConsumptionValuesSnackBar != "true") {
      return commit("updateSnackbar", {
        show: data?.show || false,
        message: data?.message || "",
        timeout: data?.timeout || 8000,
        color: data?.color || "#BF360C",
        percentage: data?.percentage || null,
        showButton: data?.showButton || null,
      });
    }
  },
  async refreshToken({ commit, getters }) {
    const refresh_token = (await getters.currentToken)
      ? getters.currentToken.refresh
      : null;
    return customAxios
      .post("/api/token/refresh/", { refresh: refresh_token })
      .then((response) => {
        commit("updateTokenAccess", response.data.access);
        return true;
      })
      .catch((e) => {
        return this.dispatch("toggleSnackbar", {
          show: true,
          message: e.response.data.error,
        });
      });
  },

  async recoverPassword({ commit, dispatch }, data) {
    const data_tmp = { email: data };
    return axios
      .post(`${config.apiUrl}/api-general/recover_password_email/`, data_tmp)
      .catch((e) => {
        return dispatch("toggleSnackbar", {
          show: true,
          message: e.response.data.error,
        });
      });
  },

  async updateBillEditValue({ commit, dispatch }, data) {
    if (data && data[0] && data[0].tariff_groups) {
      data[0].tariff_groups.forEach(async (element: any, index: any) => {
        element.tariff_plan.forEach(async (element: any) => {
          await dispatch("changeEditValue", {
            key: element.name,
            value: element.energy,
            period: index,
          });
        });
      });
      data[0].tariff_groups.forEach(async (element: any, index: any) => {
        element.tariff_plan.forEach(async (element: any) => {
          await dispatch("changeEditValue", {
            key: element.name.replace("energy", "grid"),
            value: element.grid,
            period: index,
          });
        });
      });
    }
  },

  async updateBillValue({ commit, dispatch }, data) {
    dispatch("activateSnackBar");
    if (data && data[0] && data[0].tariff_groups) {
      data[0].tariff_groups.forEach(async (element: any, index: any) => {
        element.tariff_plan.forEach(async (element: any) => {
          await dispatch("changeFieldValue", {
            key: element.name,
            value: element.energy,
            period: index,
          });
        });
      });
      data[0].tariff_groups.forEach(async (element: any, index: any) => {
        element.tariff_plan.forEach(async (element: any) => {
          await dispatch("changeFieldValue", {
            key: element.name.replace("energy", "grid"),
            value: element.grid,
            period: index,
          });
        });
      });
    }
  },

  async requestRecoverPassword({ commit, dispatch }, data) {
    return customAxios({
      data: data,
      method: "post",
      url: "/api-general/recover_password/",
    }).catch((e) => {
      return dispatch("toggleSnackbar", {
        show: true,
        message: e.response.data.error,
      });
    });
  },

  async requestChangePassword({ commit, dispatch }, data) {
    const timeout = 5000;
    return customAxios({
      data: data,
      method: "post",
      url: "/api-general/change_password/",
    }).catch((e) => {
      return dispatch("toggleSnackbar", {
        show: true,
        message: e.response.data.error,
      });
    });
  },

  changeProfileValue({ commit }, data) {
    let arr: any = {};
    const arrTmp_: any = {};
    const profileIndex = this.state.profiles.findIndex(
      (val: any) => val.id == this.state.condos_response.selected_profile
    );
    //Need to target the condominium_account object inside the user object
    if (
      data.key.address_type != null &&
      data.key.account == "condominium_account"
    ) {
      const formData = { [data.key.key]: data.value };
      commit("updateBillingAddressCondoLength", formData);
      arr = { ...formData, type: data.key.address_type };

      //Case if its a user account
    } else {
      if (
        data.key.address_type != null &&
        this.state.condos_response.selected_role == "user"
      ) {
        const formData = { [data.key.key]: data.value };
        commit("updateBillingAddressUserLength", formData),
          (arr = { ...formData, type: data.key.address_type });
      } else arr[data.key.key] = data.value;
    }

    commit("updateProfileInfo", arr);
  },
  changeCondosValue({ commit }, data) {
    const arr: any = {};
    arr[data.key] = data.value[0];
    commit("updateCondosInfo", arr);
  },

  changeEditValue({ commit }, data) {
    const arr: any = { period: data.period };
    arr[data.key] = data.value;
    //whenever plan is changed to BTN its needed to reset the regulated market value, backend doesnt accept null regulated market vals
    if (Object.keys(arr)[1] == "plan") {
      const arr_temp = { period: 0, regulated_market: true };
      commit("updateEditInfo", arr_temp);
    }

    commit("updateEditInfo", arr);
  },

  changeFieldValue({ commit }, data) {
    const arr: any = { period: data.period };
    arr[data.key] = data.value;
    //whenever plan is changed to BTN its needed to reset the regulated market value, backend doesnt accept null regulated market vals
    if (Object.keys(arr)[1] == "plan") {
      const arr_temp = { period: 0, regulated_market: true };

      commit("updateContractInfo", arr_temp);
    }

    commit("updateContractInfo", arr);
  },

  changeFaqValue({ commit }, data) {
    commit("updateFaqInfo", data);
  },
  logout({ commit, dispatch }) {
    commit("updateToken", null);
    commit("updateUser", null);
    localStorage.clear();

    router.replace({
      name: "Login",
      params: {
        wantedRoute: router.currentRoute.fullPath,
      },
    });
  },
};
const store: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== "production", //aqui
  modules: {
    // sampleRecord,
  },
  state: {
    recovery_token: "",
    billing_address_user_length: config.billing_address as any,
    billing_address_condo_length: config.billing_address as any,
    number_tariffs: 1,
    edit_number_tariffs: 1,
    period_edit: 0,
    active_tab_profile: 1,
    billing_periods: [] as BillingPeriod[],
    hub_state: [] as HubState[],
    box_state: [] as BoxState[],
    condos_response: config.condosResponse as CondosResponse,
    is_change_password: false,
    change_password: false,
    token: JSON.parse(sessionStorage.getItem("token") || "null") as Token,
    user: JSON.parse(sessionStorage.getItem("user") || "null") as User,
    button_state: [] as NameBooleanState[],
    modal_state: [] as NameBooleanState[],
    profiles: [] as User[],
    snackbar: {} as Snackbar,
    faqs: config.faqs as FaqList[],
    consumption_data: {} as ConsumptionData,
    consumption_data_prev: {} as ConsumptionData,
    sliced_session: [] as Session[],
    google_cookie: JSON.parse(
      sessionStorage.getItem("google-cookie") || "null"
    ) as NameBooleanState,
    privacy_cookie: JSON.parse(
      sessionStorage.getItem("privacy-cookie") || "null"
    ) as NameBooleanState,
    start: 0,
    counter: 0,
    street: "",

    next_enabled: false,
    buttonDisabled: false,
    consumptionDataUpdate: false,
    prev_enabled: false,
    charging_state: {} as ChargingState,
    active_tab: 0,
    active_tab_faq: 0,
    period: 0,
    contract_data: config.contractData as ContractData[],
    faq_data: config.faqData as FaqData,
    historic_data: [],
    edit_data: config.editData as ContractData[],
    tariff_info: config.tariffInfo as TariffInfo[],

    s_date: config.dateF as DateF,
    e_date: config.dateF as DateF,
    selected_consumption: {} as Consumption,
    selected_consumption_before: {} as Consumption,
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
  plugins: [
    createMultiTabState({
      key: "evedge.smartenergylab.pt",
      statesPaths: ["token", "user"],
    }),
  ],
};
export default new Vuex.Store<RootState>(store);

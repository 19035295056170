<template>
  <v-container>
    <v-col cols="12" class="pt-0 text text-5 normal-label">
      Email de acesso
    </v-col>

    <v-col cols="12" class="pt-2 pb-8 px-3">
      <v-text-field
        :placeholder="
          profiles_dmp[0] != null ? profiles_dmp[0].user_account.email : '-'
        "
        height="44px"
        background-color="#D8D8D8"
        class="filled-input"
        dense
        outlined
        disabled
      />
    </v-col>

    <v-col cols="12" class="pt-3">
      <button
        @click="changePassword"
        class="button-manage rounded d-flex justify-center align-center"
      >
        <svg
          class="mr-1"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clip-path="url(#clip0_268_974)">
            <!-- SVG content -->
          </g>
          <defs>
            <clipPath id="clip0_268_974">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Mudar Password
      </button>
    </v-col>

    <v-col cols="12" class="pt-0" style="font-size: 14px">
      <span>
        Ao clicar em <b>"Mudar Password"</b> será redirecionado para uma nova página e deve seguir as instruções.
      </span>
    </v-col>
  </v-container>
</template>

<script lang="ts">
import router from "@/router";
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  components: {},
  name: "ManageAccountAcesso",
  data() {
    return {
      isChecked: false,
      loading: false,
      hasError: false,
    };
  },
  methods: {
    changePassword() {
      return router.replace("/changePassword");
    },
  },
  computed: {
    profiles_dmp: function (): any {
      return this.$store.state.profiles.filter(
        (profile: any) => profile.id == this.condos_response.selected_profile
      );
    },
  },
});
</script>

<style lang="scss">
/* Your styles */
</style>
